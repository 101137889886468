import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,

  //PRODUCTIVO
  dominio: 'https://www.sao-trimex.com/gestorProyectosAPI/public',
  captchaKey: '6Le3KZUjAAAAAIZ53XnojVQECDhqv6LBabtL1J-E',

  //TESTING
  //dominio: 'https://sao-trimex.com/testing/gestorProyectosAPI/public',
  // captchaKey: '6Le3KZUjAAAAAIZ53XnojVQECDhqv6LBabtL1J-E',

  //LOCAL
  // dominio: 'http://localhost:8081/gestorProyectosAPI/public',
  //captchaKey: '6LeqDAweAAAAANOik-iTZgQhrjh4-a5mn2_y6Xvu',

  // dominio: 'https://allsafe-mexico.com/allsafe/gestorproyectos/gestorProyectosAPI/public',
  // captchaKey: '6LcNCikgAAAAAO1Mq0uhLJfx13wkqncRQdTgV4Kn',

  perfilUsuario: null,
  dataUsuario: null,

  permisoLogin: null,

  permisoVerEmpresas: null,
  permisoAgregarEmpresas: null,
  permisoEditarEmpresas: null,
  permisoEliminarEmpresas: null,

  permisoVerContactos: null,
  permisoAgregarContactos: null,
  permisoEditarContactos: null,
  permisoEliminarContactos: null,

  permisoVerCotizaciones: null,
  permisoAgregarCotizaciones: null,
  permisoEditarCotizaciones: null,
  permisoEliminarCotizaciones: null,

  permisoSubirListasPrecios: null,
  permisoDescargarListasPreciosActual: null,
  permisoDescargarListasPreciosTodas: null,

  permisoVerOrdenesCompra: null,
  permisoAgregarOrdenesCompra: null,
  permisoEditarOrdenesCompra: null,
  permisoEliminarOrdenesCompra: null,

  //Filtros generales de las tablas de todos los apartados
  filtroGeneral: '',
  paginacionGeneral: 1,
  resultadosPaginaGeneral: 10,

  //Filtros especificos del apartado empresas
  estatusFiltroEmpresa: 'activos-inicial',
  nombreFiltroEmpresa: '',

  //Filtros especificos del apartado contactos
  estatusFiltroContacto: 'activos-inicial',
  nombreFiltroContacto: '',
  correoFiltroContacto: '',
  empresaFiltroContacto: 0,
  tipoContactoFiltroContacto: 0,

  //Filtros especificos del apartado cotizaciones
  registroFiltroCotizacion: 0,
  vendedorFiltroCotizacion: 0,
  empresaFiltroCotizacion: 0,
  folioFiltroCotizacion: '',
  industriaFiltroCotizacion: 0,
  tipoProductoFiltroCotizacion: 0,
  caractEspecialFiltroCotizacion: 0,
  fechaInicialFiltroCotizacion: '',
  fechaFinalFiltroCotizacion: '',

  //Filtros especificos del apartado ordenes de compra
  registroFiltroOrdenCompra: 0,
  vendedorFiltroOrdenCompra: 0,
  empresaFiltroOrdenCompra: 0,
  folioFiltroOrdenCompra: '',
  ordenFiltroOrdenCompra: '',
  fechaInicialFiltroOrdenCompra: '',
  fechaFinalFiltroOrdenCompra: '',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'restartTodosFiltros':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
        resultadosPaginaGeneral: 10,

        estatusFiltroEmpresa: 'activos-inicial',
        nombreFiltroEmpresa: '',

        estatusFiltroContacto: 'activos-inicial',
        nombreFiltroContacto: '',
        correoFiltroContacto: '',
        empresaFiltroContacto: 0,
        tipoContactoFiltroContacto: 0,

        registroFiltroCotizacion: 0,
        vendedorFiltroCotizacion: 0,
        empresaFiltroCotizacion: 0,
        folioFiltroCotizacion: '',
        industriaFiltroCotizacion: 0,
        tipoProductoFiltroCotizacion: 0,
        caractEspecialFiltroCotizacion: 0,
        fechaInicialFiltroCotizacion: '',
        fechaFinalFiltroCotizacion: '',

        registroFiltroOrdenCompra: 0,
        vendedorFiltroOrdenCompra: 0,
        empresaFiltroOrdenCompra: 0,
        folioFiltroOrdenCompra: '',
        ordenFiltroOrdenCompra: '',
        fechaInicialFiltroOrdenCompra: '',
        fechaFinalFiltroOrdenCompra: '',
      }
    case 'restartPaginacion':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
      }
    case 'restartFiltrosEmpresas':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
        estatusFiltroEmpresa: 'activos-inicial',
        nombreFiltroEmpresa: '',
      }
    case 'restartFiltrosContactos':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
        estatusFiltroContacto: 'activos-inicial',
        nombreFiltroContacto: '',
        correoFiltroContacto: '',
        empresaFiltroContacto: 0,
        tipoContactoFiltroContacto: 0,
      }
    case 'restartFiltrosCotizaciones':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
        registroFiltroCotizacion: 0,
        vendedorFiltroCotizacion: 0,
        empresaFiltroCotizacion: 0,
        folioFiltroCotizacion: '',
        industriaFiltroCotizacion: 0,
        tipoProductoFiltroCotizacion: 0,
        caractEspecialFiltroCotizacion: 0,
        fechaInicialFiltroCotizacion: '',
        fechaFinalFiltroCotizacion: '',
      }
    case 'restartFiltrosOrdenesCompra':
      return {
        ...state,
        ...rest,
        paginacionGeneral: 1,
        registroFiltroOrdenCompra: 0,
        vendedorFiltroOrdenCompra: 0,
        empresaFiltroOrdenCompra: 0,
        folioFiltroOrdenCompra: '',
        ordenFiltroOrdenCompra: '',
        fechaInicialFiltroOrdenCompra: '',
        fechaFinalFiltroOrdenCompra: '',
      }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
