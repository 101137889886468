import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './scss/style.scss'
// import Page404 from './views/page404/Page404'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const Layout = React.lazy(() => import('./layout/Layout'))

//Pages
const Login = React.lazy(() => import('./views/login/Login'))

const App = () => {
  return (
    <BrowserRouter basename="/">
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route path="/" name="Home" render={(props) => <Layout {...props} />} />
          {/* <Route path="*" name="404" render={(props) => <Page404 {...props} />} /> */}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
